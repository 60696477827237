export default function SliderBtns({ handlePrev, handleNext }) {
	return (
		<>
			<button className="prev" onClick={handlePrev}>
            <span className="sr-only">Previous Slide</span>
         </button>

         <button className="next" onClick={handleNext}>
            <span className="sr-only">Next Slide</span>
         </button>
		</>
	);
}