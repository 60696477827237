import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// components
import HeroSlider from '../components/HeroSlider';
import BookingForm from '../components/BookingForm';
import ImgCarousel from '../components/ImgCarousel';
// images
import twinbed from '../imgs/twinbed.webp';
import kingbed from '../imgs/kingbed.webp';
import random from '../imgs/random.webp';
import terrus from '../imgs/terrus.webp';
import workstation from '../imgs/workstation.webp';
import maps from '../imgs/map.webp';
import building from '../imgs/building.webp';
import decor from '../imgs/decor.webp';
import foldable from '../imgs/foldable.webp';
import space from '../imgs/storage.webp';
import kitchenstorage from '../imgs/kitchenstorage.webp';
import fire from '../imgs/fire.webp';
import cctv from '../imgs/cctv.webp';
import security from '../imgs/security.webp';
import ac from '../imgs/ac.webp';
import geyser from '../imgs/geyser.webp';
import induction from '../imgs/induction.webp';


export default function Home() {
	return (
      <>
         <Helmet>
            <title>The Studio Apartments Hyderabad</title>
            <meta  name="description" content="Make booking easy and take your customers on trips around the world with an attractive design with our Tour and Travel Booking React Website template for your Travel agency" />
         </Helmet>

         <HeroSlider slides={[ twinbed, kingbed, workstation, terrus ]}>
            <motion.div
               className="bg-blur"

               initial={{ opacity: 0, y: '100%' }}
               animate={{ opacity: 1, y: 0 }}
               transition={{ duration: .7, ease: 'easeInOut' }}
               exit={{ opacity: 0, y: '100%' }}
               >
                  <h2>Your Perfect Studio Awaits</h2>
                  <p>Discover the art of modern living with sleek designs and unmatched comfort. Embrace the freedom of modern studio living with everything you need right at your doorstep. Beautifully designed studio apartments tailored for the life you love!</p>
               </motion.div>
         </HeroSlider>


         <section className="pt-6 pb-6">
            <div className="container grid align-items-start gtc-1-2 g-2">
               <div className="fs-md txt-right">
                  <p>Are you looking for a modern, compact home that combines convenience, comfort, and connectivity?</p>
                  
                  <img src={maps} className="mbk-2" alt="The Studio Apartments Hyderabad" />
                  
                  <p className="fs-md fw-600 txt-secondary">Our property is strategically located in Gachibowli, offering close proximity to major IT hubs, corporate offices, and popular social hotspots. Enjoy the convenience of living just minutes away from workplaces, malls, and entertainment centers, making it an ideal choice for professionals and families alike.</p>
               </div>

               <div>
                  <p><span className="dropcap">1 RK</span><small>Our flats are thoughtfully designed to meet the needs of modern professionals and families. With built-in smart storage solutions, essential appliances, and ergonomic layouts, these homes maximize space without compromising style. </small></p>
                  
                  <p>Our flats stand out with their smart designs and thoughtful amenities. Each home features space-saving solutions, essential appliances like air conditioning and geysers, and layouts tailored for modern lifestyles. Located strategically in Gachibowli, they offer unparalleled convenience, being minutes away from top IT parks, offices, and recreational hotspots.</p>
                  
                  <div className="grid g-2 mbk-3">
                     <img src={building} alt="The Studio Apartments Hyderabad" />
                     <img src={decor} alt="The Studio Apartments Hyderabad" />
                  </div>

                  <p>Nestled in the vibrant locality of Gachibowli, our property boasts unparalleled connectivity to major IT hubs, corporate offices, and renowned educational institutions. Residents will find themselves just a short commute away from prominent workplaces, ensuring a seamless work-life balance. Additionally, the area offers an array of shopping malls, trendy cafes, and entertainment options, providing endless opportunities for leisure and socializing. Whether you’re a professional looking for convenience or a family seeking vibrant surroundings, this location perfectly complements your lifestyle.</p>
               </div>
            </div>
         </section>

         <section className="grid gtc-2-1 g-2 pb-2">
            <ImgCarousel
               slides={[ twinbed, kingbed, random ]}
               btnSide="right"
            />

            <div className="box-light">
               <h3>A Lifestyle that fits your Ambition</h3>
               <h2>Ideal for Couples or Professionals</h2>
               <p>Whether you're a young couple starting your journey or a busy professional seeking a hassle-free home, our 1 RK flats are designed to complement your dynamic lifestyle. Enjoy modern amenities, ergonomic designs, and an inviting ambiance that supports productivity, relaxation, and quality time, all in a compact yet stylish setting.</p>
            </div>
         </section>

         <section className="grid gtc-1-2 g-2 pb-2">
            <div className="box-light">
               <h3>Smart Living, Space Maximized!</h3>
               <h2>Optimal Space Utilization</h2>
               <p>All flats come equipped with smart storage solutions and a foldable workstation, designed to maximize space and functionality. With multi-functional furniture, vertical storage, and adaptable features, these spaces offer the perfect blend of practicality and modern living.</p>
            </div>

            <ImgCarousel
               slides={[ foldable, space, kitchenstorage ]}
               btnSide="left"
            />
         </section>

         <section className="grid gtc-2-1 g-2 pb-2">
            <ImgCarousel
               slides={[ induction, ac, geyser ]}
               btnSide="right"
            />

            <div className="box-light">
               <h3>Thoughtful Additions, Effortless Living</h3>
               <h2>Every Detail, Designed for Comfort</h2>
               <p>Our 1 RK flats come equipped with essential appliances like air conditioning, an electric stove, a refrigerator, and a geyser to ensure your comfort and convenience. These modern additions are thoughtfully integrated to enhance your living experience, making your home functional, efficient, and ready to move in.</p>
            </div>
         </section>

         <section className="grid gtc-1-2 g-2 pb-6">
            <div className="box-light">
               <h3>Your Safety, Our Priority</h3>
               <h2>Secure and Reliable Homes</h2>
               <p>The flats are built with your safety in mind, featuring robust security systems, fire safety measures, and modern elevators with advanced safety features. The building is designed to comply with the highest standards of safety, providing you with peace of mind. Whether it’s secure entry systems, fire-resistant construction, or well-maintained lift, every detail ensures a safe and worry-free living environment.</p>
            </div>

            <ImgCarousel
               slides={[ fire, cctv, security ]}
               btnSide="left"
            />
         </section>

         <section className="container pb-6">
            <h2 className="txt-center">Dial <a href="tel:9281446466" class="txt-secondary">9281446466</a> to Schedule a Home Visit</h2>
         </section>
      </>
	);
}
