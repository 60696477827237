// hook component
import useSlider from '../hooks/useSlider';
// component
import SliderBtns from './SliderBtns';


export default function ImgCarousel({ slides, btnSide }) {
   const [current, handlePrev, handleNext] = useSlider(slides);

   return (
      <div
         className={"carousel btn-side-" + btnSide}
         style={{ backgroundImage: `url(${slides[current]})` }}
      >
         <SliderBtns
            handlePrev={handlePrev}
            handleNext={handleNext}
         />
      </div>
   );
}