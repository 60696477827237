export default function WhatsAppBtn() {
   return (
      <a
         id="wachat"
         href="https://wa.link/kh7p9d"
         rel="noreferrer"
         target="_blank"
      >
         <p className="wamessage">Hi, how can we help you?</p>
      </a>
   );
}