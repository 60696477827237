import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
// component
import FollowUs from './FollowUs';
// image
import logo from '../imgs/logo.png';


export default function Footer() {
   const dateYear = new Date().getFullYear();
   return (
      <motion.footer
         initial={{ opacity: 0, y: '100%' }}
         animate={{ opacity: 1, y: 0 }}
         transition={{ duration: 1, ease: 'easeInOut' }}
         exit={{ opacity: 0, y: '100%' }}
      >

         <div className="grid gtc-3 g-3 align-items-start">
            <div>
               <h3>About</h3>
               <p>European-style 1RK Studio Rentals - designed for optimal space utilization and modern living.</p>
               <img src={logo} className="pb-2 footer-logo" alt="The Studio Apartments Hyderabad" />
            </div>

            <ul className="w-100">
               <li><h3>Address & Contacts</h3></li>
               <li><address>Plot No: 55, TNGOS Colony, Phase 1,<br/>Gachibowli, Hyderabad<br/>Telangana - 500032</address></li>
               <li>Phone: <a href="tel:9281446466">+91 9281446466</a></li>
               {/*<li>Email: <a href="mailto:thestudioapartmentshyderabad@gmail.com">thestudioapartmentshyderabad@gmail.com</a></li>*/}
            </ul>

            <FollowUs />
         </div>
         
         <p className="txt-center pt- pb-2">&copy; The Studio Apartments Hyderabad {dateYear}.</p>
      </motion.footer>
   );
}